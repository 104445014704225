import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { OnScrollPopup } from "../hr-chatbot";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");
const image1 = require("../../../assets/images/market_ui/zendesk/1.png");
const image2 = require("../../../assets/images/market_ui/zendesk/2.png");
const image3 = require("../../../assets/images/market_ui/zendesk/3.png");
const image4 = require("../../../assets/images/market_ui/zendesk/4.png");
const image5 = require("../../../assets/images/market_ui/zendesk/5.png");
const image6 = require("../../../assets/images/market_ui/zendesk/6.png");
const image7 = require("../../../assets/images/market_ui/zendesk/7.png");
const image8 = require("../../../assets/images/market_ui/zendesk/8.png");
const image9 = require("../../../assets/images/market_ui/zendesk/9.png");
const image10 = require("../../../assets/images/market_ui/zendesk/10.png");
const image11 = require("../../../assets/images/market_ui/zendesk/11.png");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Free Zendesk Chatbot Guide: Build Your Zendesk Support Chatbot"
        description="Learn how to build a Zendesk chatbot in Slack and Teams. Automate ticket creation, updates, and modifications to improve workplace support. Get started now!"
        keywords={[
          "Zendesk Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "helpdesk chatbot free trial",
          "Zendesk Chatbot",
        ]}
        ogImage={image1}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1>The Ultimate Guide to Zendesk Chatbot</h1>
                        </div>
                      </div>
                      <section className="table_contents_wrapper">
                        <h3>Table of contents</h3>
                        <div className="table_contents_link">
                          <AnchorLink offset={180} href="#section1">
                            1. What is Zendesk Chatbot?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section2">
                            2. How does Zendesk Chatbot works?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section3">
                            3. What is Zendesk chatbot used for?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section4">
                            4. Business benefits of Zendesk chatbot
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section5">
                            5. Best Zendesk Chatbot platform
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section6">
                            6. Companies that are using Chatbot for Automated
                            Workflows
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section7">
                            7. How to Build Zendesk Chatbot Without coding
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section8">
                            8. Conclusion
                          </AnchorLink>
                        </div>
                      </section>
                      {/* Section 1 */}
                      <div
                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page"
                        id="section1"
                      >
                        <h2>What is Zendesk Chatbot? </h2>
                        <p>
                          Zendesk helps companies deliver great experiences —
                          whether helping companies supporting employees at
                          workplace or during customer service, one has to just
                          experience the magic behind Zendesk. Customers rely on
                          Zendesk to create and manage tickets, deliver
                          omni-channel experience and help agents get task done
                          quickly.
                        </p>
                        <p>
                          But what if I told you can automate your channel
                          interactions using Zendesk Chatbot? Yes, you can.
                        </p>
                        <p>
                          Zendesk Chatbot is an AI powered Zendesk which helps
                          companies auto-resolve 80% of customer/employee issues
                          within seconds. Using Zendesk chatbot, companies
                          benefit by automating repetitive issues, save costs,
                          reduce time to resolution, deliver faster support and
                          more.
                        </p>
                      </div>
                      {/* Section 2 */}
                      <div
                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page"
                        id="section2"
                      >
                        <h2>How does Zendesk Chatbot works? </h2>
                        <p>
                          <a href="https://workativ.com/conversational-ai-platform/zendesk-chatbot">
                            Zendesk chatbots
                          </a>{" "}
                          use Natural Language Processing to understand a user’s
                          intention or utterances for initiating a conversation
                          with it as well as to extract required data from a
                          user’s query and pass it to the automation to resolve
                          the user’s issue in real-time. So, think of how
                          intelligent chatbots work, but with Zendesk chatbot,
                          it can not only have those benefits, but also extend
                          the functionalities of Zendesk tool using advanced
                          integration and workflows.
                        </p>
                      </div>
                      {/* Section 3 */}
                      <div
                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page"
                        id="section3"
                      >
                        <h2>
                          What is{" "}
                          <a href="https://workativ.com/conversational-ai-platform/zendesk-chatbot">
                            Zendesk chatbot
                          </a>{" "}
                          used for?{" "}
                        </h2>

                        <p>
                          A{" "}
                          <span>
                            <a href="https://workativ.com/conversational-ai-platform/zendesk-chatbot">
                              Zendesk chatbot
                            </a>
                          </span>{" "}
                          can be quite helpful not just for your users but also
                          for the agents as it helps automate user interactions
                          on repetitive tasks and helps your team focus on more
                          important and complex tasks that require human touch.
                        </p>
                        <div className="p_with_ul">
                          <p>
                            We have compiled a list of most repetitive tasks
                            that you can automate using Zendesk chatbot:
                          </p>

                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              {" "}
                              <a href="https://help.workativ.com/knowledgebase/create-support-ticket-2/">
                                Adding a New Zendesk Ticket{" "}
                              </a>
                            </li>
                            <li>
                              <a href="https://help.workativ.com/knowledgebase/create-support-ticket-2/">
                                Creating a Zendesk Support Ticket{" "}
                              </a>
                            </li>
                            <li>
                              <a href="https://help.workativ.com/knowledgebase/delete-ticket-3/">
                                Deleting a Zendesk Ticket
                              </a>
                            </li>
                            <li>
                              <a href="https://help.workativ.com/knowledgebase/remove-user/">
                                Removing a Zendesk User{" "}
                              </a>
                            </li>
                            <li>
                              <a href="https://help.workativ.com/knowledgebase/update-user/">
                                Updating a Zendesk User’s Details{" "}
                              </a>
                            </li>
                            <li>
                              <a href="https://help.workativ.com/knowledgebase/get-ticket-details/">
                                Retrieving a Zendesk Ticket’s Details{" "}
                              </a>
                            </li>
                            <li>Updating a Zendesk Ticket’s Details</li>
                            <li>Access Management </li>
                            <li>
                              <a href="">Asset Requests </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/use-cases/unlock-account-automation">
                                Unlock Account
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/use-cases/reset-password-automation">
                                Password Resets 
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/use-cases/user-provisioning-automation">
                                User Provisioning 
                              </a>{" "}
                            </li>
                            <li>
                              <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                                Employee Onboarding 
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/use-cases/employee-offboarding-automation">
                                Employee Offboarding
                              </a>
                            </li>
                          </ul>
                        </div>
                        <NocodeWrapper />
                      </div>
                      {/* Section 4 */}
                      <div
                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page"
                        id="section4"
                      >
                        <h2>Business benefits of Zendesk chatbot </h2>
                        <div className="market_sub_wrapper">
                          <h3>
                            A. Customers and users get faster responses and
                            issue resolutions{" "}
                          </h3>
                          <p>
                            Customer experience long wait times with traditional
                            customer support, but AI can take on common, simple,
                            repetitive customer questions or requests much
                            faster and able to solve 80% of the issues in
                            seconds. So having a Zendesk chatbot can boost your
                            mean time to respond and resolve by 5x.
                          </p>
                        </div>
                        <div className="market_sub_wrapper">
                          <h3>B. Automate repetitive work </h3>
                          <p>
                            One of the most repetitive issues or requests coming
                            to your help desk team are related to access.
                            Companies use IAM tool to record employee details,
                            login and manage access credentials to make
                            accessibility easier for your employees — be it
                            access to their laptops/desktops or apps. There are
                            a bunch of IAM offering online like JumpCloud,
                            Active Directory, OneLogin, Okta, Auth0, Office365,
                            etc., So what exactly happens when your employee
                            gets locked out of her/his laptop/desktop or forgets
                            the password to their work email? Your employee will
                            have to contact the help desk team or raise a ticket
                            on Zendesk, which again goes through the vicious
                            cycle of your employee waiting for a long time for a
                            help desk agent to take over their issue and solve
                            it for them which in turn leads to frustration and
                            loss in productivity for both the employee as well
                            as the help desk agent. Since unlocking an account
                            or resetting a password comes under repetitive
                            tasks, why not automate them and let your employees
                            resolve these issues themselves, freeing up your
                            help desk agents to focus on complex issues? How you
                            ask? That’s where{" "}
                            <span>
                              <a href="https://workativ.com/conversational-ai-platform/zendesk-chatbot">
                                Zendesk Chatbots
                              </a>
                            </span>{" "}
                            comes in.
                          </p>
                        </div>
                        {/* <HelpdeskWrapper
                          header={"5 Help Desk Tasks Everyone Should Automate."}
                        /> */}
                        <div className="market_sub_wrapper">
                          <h3>C. Modern self-service</h3>
                          <p>
                            In case an employee in your organization is facing
                            an issue with their system, be it in terms of
                            software or hardware that requires human
                            intervention in person, instead of calling up the
                            help desk and waiting for someone to answer their
                            call, they can just open their Slack or{" "}
                            <span>
                              <a href="https://medium.com/workativ-ai-automation/4-little-known-reasons-why-you-should-use-microsoft-teams-as-a-digital-help-desk-for-employee-fc0494091366">
                                Microsoft Teams
                              </a>
                            </span>{" "}
                            app on their mobile phone and chat with the Zendesk
                            chatbot to create a service request or resolve the
                            issue on their own. Thus giving employees the
                            seamless{" "}
                            <span>
                              <a href="https://workativ.com/conversational-ai-platform/it-helpdesk-chatbot">
                                self-service support
                              </a>
                            </span>{" "}
                            experience they deserve.
                          </p>
                        </div>
                        <div className="market_sub_wrapper">
                          <h3>
                            D. Free up your IT or HR staff with workflow
                            automation 
                          </h3>
                          <p>
                            Why just stop with freeing up your IT staff alone by
                            getting the repetitive tasks off their hands with
                            Zendesk chatbots? You can even ease the workload on
                            your HR with{" "}
                            <span>
                              <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                                HR worflow automation
                              </a>
                            </span>{" "}
                            using Zendesk Chatbot.
                          </p>
                          <p>
                            No more requiring employees to contact your HR to
                            know leave types or leaves available or making your
                            employees go through the hassle of logging in to the
                            HRMS portal and navigating through the portal’s
                            labyrinthine menus to find what they are looking
                            for. Using Zendesk chatbot on your business’ Slack
                            or Microsoft Teams workspace, you can let your
                            employees take care of all HR-related queries on
                            their own, while they are on the road. 
                          </p>
                        </div>
                        <div className="market_sub_wrapper">
                          <h3>E. Increased employee productivity  </h3>
                          <p>
                            High volume support ticket can put a huge pressure
                            and burden on your support agents leaving them with
                            big pile of open tickets to resolve. Tickets that
                            are repetitive in nature can eat up your agent's
                            time. For instance, a query like a password reset
                            requires a particular flow of steps. Therefore,
                            Zendesk Chatbot help in faster resolution for this
                            type of tickets.
                          </p>
                          <p>
                            This gives both agents employees a better experience
                            and more time to do productive work instead of
                            back-and-forth calls and emails to help desk.
                          </p>
                        </div>
                      </div>
                      {/* Section 5 */}
                      <div
                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page"
                        id="section5"
                      >
                        <div className="">
                          <h2>
                            Best Zendesk Chatbot platform –{" "}
                            <span style={{ fontFamily: "Roboto-Regular" }}>
                              we have compiled a list of popular platforms to
                              help you build your Zendesk chatbot{" "}
                            </span>
                          </h2>
                          <div className="p_with_ul section_5">
                            <ul
                              className="section__ul"
                              style={{ width: "100%", float: "left" }}
                            >
                              <li>
                                <b>1. Netomi</b> - chatbot platform that helps
                                you automate your conversations{" "}
                              </li>
                              <li>
                                <b>
                                  {" "}
                                  2.{" "}
                                  <span>
                                    {" "}
                                    <a href="https://workativ.com/conversational-ai-platform/">
                                      {" "}
                                      Workativ Assistant
                                    </a>
                                  </span>{" "}
                                </b>{" "}
                                – no code platform with both chatbot and
                                workflow automation tools. Pricing starts at
                                $299 monthly.{" "}
                              </li>
                              <li>
                                <b>3. Reply.ai</b>{" "}
                              </li>
                              <li>
                                <b>4. Aivo</b>
                              </li>
                            </ul>
                          </div>
                          <div className="market_sub_wrapper" id="section6">
                            <h3>
                              Companies that are using Chatbot for Automated
                              Workflows
                            </h3>
                            <p>
                              Since pandemic, several businesses around the
                              world are now looking toward Intelligent
                              Automation – the most advanced type of automation
                              wherein AI-based contextual chatbots with
                              automated workflows support customers and
                              employees by resolving their support issues in an
                              instant, on-the-go, conversationally. Let’s take a
                              look at some of the businesses that have achieved
                              this and more. 
                            </p>
                          </div>
                          <div className="market_sub_wrapper">
                            <h4>1. JobAdder </h4>
                            <p>
                              JobAdder, a company offering recruitment software,
                              has significantly sped up the recruitment and
                              hiring processes in their company via automation
                              due to the speed at which processes can be
                              approved back and forth. 
                            </p>
                            <p>
                              JobAdder team has also been able to completely
                              eliminate “old school” communication hardware,
                              such as fax machines, scanners, and printers.
                              Tasks that were previously paper-based have been
                              digitized, improving efficiency across the board. 
                            </p>
                            <h4>2. Instacart  </h4>
                            <p>
                              Instacart, a grocery delivery, and pick-up service
                              offering company were able to dramatically
                              simplify the personal shopper onboarding process
                              via automation. New applicants initiate the
                              workflow by filling in basic information, such as
                              name, email address, home address, etc.  The
                              applicant’s information then automatically
                              populates duplicate fields across all the
                              onboarding forms, significantly reducing the
                              amount of information applicants must enter.
                            </p>{" "}
                            <p>
                              And the entire process easily takes place on a
                              mobile device.
                            </p>
                          </div>
                          <div className="market_sub_wrapper" id="section7">
                            <h3>How to Build Zendesk Chatbot Without coding</h3>
                            <div className="top_ul">
                              <section class="table_contents_wrapper">
                                <div class="table_contents_link">
                                  <AnchorLink offset={120} href="#section11">
                                    a) Zendesk + Workativ Assistant — What it
                                    means for you {" "}
                                  </AnchorLink>
                                  <AnchorLink offset={120} href="#section12">
                                    b) Steps to build Zendesk Chatbot
                                  </AnchorLink>
                                  <AnchorLink offset={120} href="#section13">
                                    c) best practises on creating an engaging
                                    chatbot {" "}
                                  </AnchorLink>
                                </div>
                              </section>
                            </div>
                            <div className="market_sub_wrapper" id="section11">
                              <h4>
                                a) Zendesk + Workativ Assistant — What it means
                                for you 
                              </h4>
                              <p>
                                <span>
                                  <a href="https://workativ.com/conversational-ai-platform/">
                                    Workativ Assistant
                                  </a>
                                </span>{" "}
                                is a no-code conversational ai platform that
                                also offers workflow automation. “so what, it’s
                                just another PaaS on the market that requires
                                heavy lifting from our side to get it up and
                                going,” you might say. But that’s where workativ
                                differentiates itself. Workativ Assistant is a
                                no-code platform that lets you build and set up
                                domain-specific chatbots with app workflows on
                                your Slack or Microsoft Teams workspace in just
                                a few hours, enabling seamless self-service for
                                your employees and help desk agents, increasing
                                their productivity and getting you the best ROI
                                on your workplace support. let’s take a look at
                                how to build Zendesk Chatbot using workativ.
                              </p>
                              {/* <HelpdeskWrapper
                              header={
                                "Boost your team’s productivity with powerful new features in Workativ."
                              }
                            /> */}
                              <NocodeWrapper />
                            </div>

                            <div className="market_sub_wrapper" id="section12">
                              <h4>
                                b) Steps to build{" "}
                                <a href="https://workativ.com/conversational-ai-platform/how-to-build-zendesk-chatbot">
                                  Zendesk Chatbot
                                </a>
                              </h4>
                              <div className="p_with_ul">
                                <p>
                                  Here are the key parts to build your Zendesk
                                  chatbot. The main parts are{" "}
                                </p>
                                <ul
                                  className="section__ul"
                                  style={{ width: "100%", float: "left" }}
                                >
                                  <li>Creating a bot workspace</li>
                                  <li>
                                    Creating Zendesk workflows or use prebuilt
                                    from marketplace
                                  </li>
                                  <li>
                                    Creating dialogs or conversations for
                                    chatbot{" "}
                                  </li>
                                  <li>Deploying on slack or teams channel </li>
                                </ul>
                              </div>
                              <Faq />
                            </div>
                            <div className="market_sub_wrapper" id="section13">
                              <h4>
                                c) best practises on creating an engaging
                                chatbot 
                              </h4>
                              <p>
                                Now that we’ve seen how to build your Zendesk
                                chatbot using workativ, below are some of the
                                things you should do to{" "}
                                <span>
                                  <a href="https://blog.workativ.com/chatbot-best-practices-for-designing-a-conversational-experience-with-workativ-assistant/">
                                    create an engaging conversational experience
                                  </a>
                                </span>{" "}
                                for a user with the chatbot. 
                              </p>
                              <div className="p_with_ul">
                                <ul
                                  className="section__ul"
                                  style={{ width: "100%", float: "left" }}
                                >
                                  <li>
                                    Create the conversation based on user
                                    stories — Always structure the
                                    conversation/dialog flow based on what and
                                    how the user expects the chatbot to solve
                                    her/his query. {" "}
                                  </li>
                                  <li>
                                    Test-run with internal control groups —
                                    Testing whether the conversation that you
                                    created for the chatbot sounds friendly and
                                    natural with a select group of employees
                                    first enables you to optimize the
                                    conversation further for an engaging support
                                    experience for employees. 
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Section 6 */}

                      <div
                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page"
                        id="section8"
                      >
                        <h2>Conclusion </h2>

                        <p>
                          Since you have reached the conclusion, you must be
                          clear by now that building a Zendesk chatbot is quite
                          easy and you also deploy your chatbot on Slack or MS
                          Teams. It is found that 70% of users prefer using
                          slack for reporting their support issues. And while
                          it's great for people to interact, having a Zendesk
                          chatbot can really transform workplace support by
                          automating both interactions and as well workflows.
                        </p>
                        <p>
                          With the AI powered no-code platforms like workativ
                          assistant, you can supercharge your Zendesk
                          investments with a Zendesk chatbot. Bringing together
                          Workativ Assistant, Zendesk, and other third-party
                          tools makes your BOT no less than a SUPER-BOT.
                          Power-packed and Smart!
                        </p>
                        <p>
                          <b>
                            Can’t wait to transform your IT Helpdesk with
                            Zendesk Chatbot? Get in touch with and we’d be more
                            than happy to give you a{" "}
                            <span>
                              {" "}
                              <a href="https://workativ.com/conversational-ai-platform/demo">
                                {" "}
                                demo
                              </a>
                            </span>
                            . 
                          </b>
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/microsoft-teams-vs-slack">
                                Microsoft Teams vs. Slack: Battle of the
                                Collaboration Powerhouses
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/10-major-it-workflow-automation-benefits">
                                {" "}
                                10 Best IT Workflow Automation Practices to
                                follow for Chatbots designed with Workativ
                                Assistant
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                                What Is Conversational AI? A Guide to
                                Conversational AI Chatbots
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai/">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper">
      <h4>No Code - Free Zendesk Chatbot</h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Zendesk chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};

function Faq() {
  return (
    <React.Fragment>
      <div className="mob_accordian mob_accordian_faq">
        <section className="accordian_landing trial_accordian_landing">
          <div className="">
            <div className="col-12 pl-0 faq_landing">
              <Accordion
                allowMultipleExpanded={true}
                allowZeroExpanded
                id="accordion"
                className="accordion pl-0"
                preExpanded={["a"]}
              >
                <div className="accordion-wrapper pricing_faq">
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Step 1. Creating a bot workspace{" "}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        When you sign up for workativ assistant, you’ll be
                        required to name your workspace. You can call it HR Bot,
                        or IT Bot, or just pick something like ‘EDITH’.
                      </p>
                      <img
                        loading="lazy"
                        src={image1}
                        alt="Step 1. Creating a bot workspace"
                      />
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Step 2. Adding collaborators to the workspace{" "}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        {" "}
                        It’s best to have a group of people collaborating on
                        creating new conversation/dialog flows or automations
                        for a chatbot or to optimize existing dialogs and
                        automations. Workativ assistant enables this by allowing
                        you to invite users to the workspace via email.
                      </p>
                      <img
                        loading="lazy"
                        src={image2}
                        alt="  Step 2. Adding collaborators to the workspace"
                      />
                      <img
                        loading="lazy"
                        src={image3}
                        alt="  Step 2. Adding collaborators to the workspace"
                      />
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Step 3. Naming your bot
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Before creating dialog or topics for your chatbot and
                        for Zendesk automation, you’ll need to name your bot.
                      </p>
                      <img
                        loading="lazy"
                        src={image4}
                        alt=" Step 3. Naming your bot"
                      />
                      <p>
                        So that’s all there is to the initial set up of your
                        workspace. Next, let’s move on to building dialog and
                        setting up Zendesk workflows. {" "}
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Step 4. Setting up a Zendesk automation for the chatbot
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Before we building dialogs, lets setup Zendesk create
                        ticket workflow so we can use this workflow in our
                        chatbot whilst building dialogs. Zendesk ticket
                      </p>
                      <img
                        loading="lazy"
                        src={image5}
                        alt="  Step 4. Setting up a Zendesk automation for the chatbot"
                      />
                      <p>
                        You’ll just have to start by specifying the inputs
                        required from the user for the automation to execute
                        tasks in the first step. 
                      </p>
                      <p>
                        Then you’ll have to select the Zendesk action that you
                        want to automate on the action panel in Step-2. In this
                        example, we use create support ticket workflow action.{" "}
                      </p>
                      <img
                        loading="lazy"
                        src={image6}
                        alt="  Step 4. Setting up a Zendesk automation for the chatbot"
                      />
                      <p>
                        Then you’ll have to connect to your Zendesk account on
                        the actions panel by entering the Zendesk domain URL,
                        username, and password. {" "}
                      </p>
                      <img
                        loading="lazy"
                        src={image7}
                        alt="  Step 4. Setting up a Zendesk automation for the chatbot"
                      />
                      <p>And you’re set. </p>
                      <p>
                        You can even club Zendesk with your other 3rd party
                        business applications for the real-time resolution of an
                        issue (as in the example below, the case of unlocking an
                        account in Azure AD and then creating a ticket in
                        Zendesk ticketing system)
                      </p>
                      <img
                        loading="lazy"
                        src={image8}
                        alt="  Step 4. Setting up a Zendesk automation for the chatbot"
                      />
                      <p>
                        Now that we have setup our workflow. Let's move to
                        building dialogs or conversations.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Step 5. Setting up a conversation/dialog flow for the
                        chatbot 
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Creating a dialog for the chatbot using workativ’s
                        chatbot builder is easy.
                      </p>
                      <img
                        loading="lazy"
                        src={image9}
                        alt="Step 5. Setting up a conversation/dialog flow for the chatbot  "
                      />
                      <p>
                        You’ll just have to specify the user phrases that the
                        chatbot, upon detection on the user’s query, should
                        execute the dialog for (User’s Intention). Then you can
                        set up the conversation flow accordingly based on the
                        inputs you require from the user.
                      </p>
                      <p>
                        After setting up the dialog by mapping the relevant
                        automation to it, you can test the whole setup using the{" "}
                        <b>Try Me</b> option on Workativ Assistant.
                      </p>
                      <img
                        loading="lazy"
                        src={image10}
                        alt="Step 5. Setting up a conversation/dialog flow for the chatbot  "
                      />
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Step 6. Launching the chatbot in chat channels 
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        After checking whether everything is working as it’s
                        supposed to, you can then add the chatbot to your
                        Microsoft Teams or Slack workspace for your employees to
                        self-serve their IT issues, on-the-go, in an instant. 
                      </p>
                      <img
                        loading="lazy"
                        src={image11}
                        alt="Step 6. Launching the chatbot in chat channels   "
                      />
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </Accordion>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
